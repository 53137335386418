// components/Meta
/* eslint-disable no-undefined */
import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

interface IMetaProps {
  ogTitle: string,
  ogDescription?: string,
  title: string,
  siteUrl: string
}

const Meta: React.FC<IMetaProps> = ({ ogTitle, ogDescription, title, siteUrl }): JSX.Element => {
  const { t } = useTranslation();
  return (
    <HelmetProvider>
      <Helmet>
        <title>{title}</title>
        <meta property="og:site_name" content={title} />
        <meta property="og:title" content={ogTitle} />
        <meta property="og:url" content={siteUrl} />
        <meta property="og:description" content={ogDescription ? ogDescription : t('description')} />
        <link rel="canonical" href={siteUrl} />
      </Helmet>
    </HelmetProvider>
  );
};

export default Meta;