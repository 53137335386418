import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Meta from "../utils/meta";
import { GREY_05, GREY_08, MAIN_COLOR } from "../config/color";
import { Link } from "react-router-dom";

const Home: React.FC = () => {
  const { t } = useTranslation()

  return (
    <>
      <Meta
        ogTitle={t('homeOgTitle')}
        ogDescription={t('homeOgDescription')}
        title={t('homeTitle')}
        siteUrl={'https://www.support-cal.com/'}
      />
      <Wrap>
        <Container>
          <h1>{t('homeTitle')}</h1>
          <div>
            <LinkContainer>
              <Link  to={"/compound"}>
                <span>{t('compoundTitle')}</span>
              </Link>
              <Link  to={"/unit-price"}>
                <span>{t('unitPriceTitle')}</span>
              </Link>
              <Link  to={"/stock-unit-price"}>
                <span>{t('stockUnitPriceTitle')}</span>
              </Link>
            </LinkContainer>
          </div>
        </Container>
      </Wrap>
    </>
  );
};

const Wrap = styled.div`
  width: 100%;
  height: cal(100vh - 60px);
  padding: 80px 0px 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Container = styled.main`
  width: 100%;
  max-width: 620px;
  padding: 24px;
  background-color: white;
  border-radius: 10px;
  border: 1px solid ${GREY_05};

  @media screen and (max-width: 500px) {
    border-radius: unset;
    max-width: 100%;
    border-top: 1px solid ${GREY_05};
    padding: 24px;
  }

  h1 {
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 20px;
  }

`;

const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${GREY_08};
  border-radius: 8px;
  gap: 20px;
  padding: 20px;

  a {
    border: 1px solid ${GREY_08};
    display: flex;
    width: 100%;
    padding: 20px;
    border-radius: 8px;
    font-weight: bold;

    &:hover {
      border-color: ${MAIN_COLOR}
    }
  }
`


export default Home;
