import React from 'react';
import styled from 'styled-components';
import Input from '../components/input/index';
import _ from 'lodash';
import numeral from 'numeral';
import { GREY_05, GREY_08, GREY_09, MAIN_COLOR } from '../config/color';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import Meta from '../utils/meta';


const UnitPrice: React.FC = () => {
  const { t } = useTranslation()

  const [inputState, setInputState] = React.useState({
    unitPrice0: 0,
    unitPrice1: 0,
    unitPrice2: 0,
    unitPrice3: 0,
    unitPrice4: 0,
    unitPrice5: 0,
    quantity0: 0,
    quantity1: 0,
    quantity2: 0,
    quantity3: 0,
    quantity4: 0,
    quantity5: 0,
  });

  const [firstCalculate, setFirstCalculate] = React.useState<number>(0);
  const [secondCalculate, setSecondCalculate] = React.useState<number>(0);
  const [thirdCalculate, setThirdCalculate] = React.useState<number>(0);
  const [fourthCalculate, setFourthCalculate] = React.useState<number>(0);
  const [fifthCalculate, setFifthCalculate] = React.useState<number>(0);
  const [sixthCalculate, setSixthCalculate] = React.useState<number>(0);

  const [isUnitPriceAverage, setIsUnitPriceAverage] = React.useState<number>(0);
  const [isQuantityTotal, setIsQuantityTotal] = React.useState<number>(0);
  const [isTotal, setIsTotal] = React.useState<number>(0);

  const {
    unitPrice0,
    unitPrice1,
    unitPrice2,
    unitPrice3,
    unitPrice4,
    unitPrice5,
    quantity0,
    quantity1,
    quantity2,
    quantity3,
    quantity4,
    quantity5,
  } = inputState

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (_.isNaN(+e.target.value.toString().replace(/,/g, ""))) return;

    setInputState({
      ...inputState,
      [e.target.name]: e.target.value,
    });
  };

  const getDecimalComma = (num: number) => {
    return numeral(num).format("0,0");
  };

  React.useEffect(() => {
    
    setFirstCalculate(Number(unitPrice0.toString().replace(/,/g, "")) * Number(quantity0.toString().replace(/,/g, "")))
    setSecondCalculate(Number(unitPrice1.toString().replace(/,/g, "")) * Number(quantity1.toString().replace(/,/g, "")))
    setThirdCalculate(Number(unitPrice2.toString().replace(/,/g, "")) * Number(quantity2.toString().replace(/,/g, "")))
    setFourthCalculate(Number(unitPrice3.toString().replace(/,/g, "")) * Number(quantity3.toString().replace(/,/g, "")))
    setFifthCalculate(Number(unitPrice4.toString().replace(/,/g, "")) * Number(quantity4.toString().replace(/,/g, "")))
    setSixthCalculate(Number(unitPrice5.toString().replace(/,/g, "")) * Number(quantity5.toString().replace(/,/g, "")))

  }, [unitPrice0, quantity0, unitPrice1, quantity1, unitPrice2, quantity2, unitPrice3, quantity3, unitPrice4, quantity4, unitPrice5, quantity5])



  React.useEffect(() => {
    setIsUnitPriceAverage(isTotal / isQuantityTotal)
  }, [isTotal, isQuantityTotal])

  React.useEffect(() => {
    setIsQuantityTotal(Number(quantity0.toString().replace(/,/g, "")) + Number(quantity1.toString().replace(/,/g, "")) + Number(quantity2.toString().replace(/,/g, "")) + Number(quantity3.toString().replace(/,/g, "")) + Number(quantity4.toString().replace(/,/g, "")) + Number(quantity5.toString().replace(/,/g, "")))
  }, [quantity0, quantity1, quantity2, quantity3, quantity4, quantity5])

  React.useEffect(() => {
    setIsTotal(firstCalculate + secondCalculate + thirdCalculate + fourthCalculate + fifthCalculate + sixthCalculate)
  }, [firstCalculate, secondCalculate, thirdCalculate, fourthCalculate, fifthCalculate, sixthCalculate])

  
  // console.log(quantity1)

  return (
    <>
      <Meta
        ogTitle={t('ogTitle')}
        ogDescription={t('unitPriceDescription')}
        title={t('unitPriceTitle')}
        siteUrl={'https://www.support-cal.com/unit-price'}
      />
      <Wrap>
        <h1>{t('unitPriceTitle')}</h1>
        <hr />
        <Container>
          <HeaderContainer>
            <TotalParagraph>
              <span>단가</span>
            </TotalParagraph>
            <TotalParagraph>
              <span>수량</span>
            </TotalParagraph>
          </HeaderContainer>
          <InputContainer>
            <Input
              row={false}
              name="unitPrice0"
              value={unitPrice0 ? getDecimalComma(unitPrice0) : ""}
              placeholder={"1,000,000"}
              onChange={handleChange}
            />
            <Input
              row={false}
              name="quantity0"
              value={quantity0 ? getDecimalComma(quantity0) : ""}
              placeholder={"10"}
              onChange={handleChange}
            />
          </InputContainer>
          <InputContainer>
            <Input
              row={false}
              name="unitPrice1"
              value={unitPrice1 ? getDecimalComma(unitPrice1) : ""}
              placeholder={"1,000,000"}
              onChange={handleChange}
            />
            <Input
              row={false}
              name="quantity1"
              value={quantity1 ? getDecimalComma(quantity1) : ""}
              placeholder={"10"}
              onChange={handleChange}
            />
          </InputContainer>
          <InputContainer>
            <Input
              row={false}
              name="unitPrice2"
              value={unitPrice2 ? getDecimalComma(unitPrice2) : ""}
              placeholder={"1,000,000"}
              onChange={handleChange}
            />
            <Input
              row={false}
              name="quantity2"
              value={quantity2 ? getDecimalComma(quantity2) : ""}
              placeholder={"10"}
              onChange={handleChange}
            />
          </InputContainer>
          <InputContainer>
            <Input
              row={false}
              name="unitPrice3"
              value={unitPrice3 ? getDecimalComma(unitPrice3) : ""}
              placeholder={"1,000,000"}
              onChange={handleChange}
            />
            <Input
              row={false}
              name="quantity3"
              value={quantity3 ? getDecimalComma(quantity3) : ""}
              placeholder={"10"}
              onChange={handleChange}
            />
          </InputContainer>
          <InputContainer>
            <Input
              row={false}
              name="unitPrice4"
              value={unitPrice4 ? getDecimalComma(unitPrice4) : ""}
              placeholder={"1,000,000"}
              onChange={handleChange}
            />
            <Input
              row={false}
              name="quantity4"
              value={quantity4 ? getDecimalComma(quantity4) : ""}
              placeholder={"10"}
              onChange={handleChange}
            />
          </InputContainer>
          <InputContainer>
            <Input
              row={false}
              name="unitPrice5"
              value={unitPrice5 ? getDecimalComma(unitPrice5) : ""}
              placeholder={"1,000,000"}
              onChange={handleChange}
            />
            <Input
              row={false}
              name="quantity5"
              value={quantity5 ? getDecimalComma(quantity5) : ""}
              placeholder={"10"}
              onChange={handleChange}
            />
          </InputContainer>
          <ResultContainer>
            <TotalResultParagraph>
              <span>평균 가격</span>
              <span>₩{getDecimalComma(isUnitPriceAverage)}</span>
            </TotalResultParagraph>
            <TotalResultParagraph>
              <span>총 수량</span>
              <span>{getDecimalComma(isQuantityTotal)}개</span>
            </TotalResultParagraph>
          </ResultContainer>
          <ContentDiv>
            <div>
              <h2>
                코인 평단가 계산기
              </h2>
              <p>
                코인 평단가/물타기 계산기입니다. 코인 평단가/물타기에 사용하세요.
              </p>
              <p>
                평단가 및 수량, 매수액이 자동 계산됩니다.
              </p>
            </div>
          </ContentDiv>
          <ContentDescriptionDiv>
            <div>
              <h2>
                코인 평단가/물타기 계산기
              </h2>
              <li>
                코인 평단가/물타기 계산기입니다.
              </li>
              <li>
                최대 6개 까지 계산 가능합니다.
              </li>
              <li>
                수수료가 적용되지 않습니다. 참고용으로만 사용하세요!!!
              </li>
            </div>
          </ContentDescriptionDiv> 
          <UsageContainer>
            <h2>사용법</h2>
            <div>
              <strong>코인 계산기(평단가 계산기)</strong>는 코인을 추가로 매입할 때 평균 취득 단가를 구하기 위한 도구입니다.<br/><br/>
              <p>
                코인 물타기 계산기는 코인 투자의 다른 말로 분할 매수, 분할 매도 기법이다. 분할 매수, 분할 매도는 코인 투자에서 가장 기본이 되는 투자 방법이다. 분할 매수, 분할 매도하지 않는 투자자는 투자가 아닌 투기를 하게 되어 정말 큰 손실을 가져올 수 있는 초보 투자가가 될 확률이 높다. 내가 매수한 코인이 손실을 입었다고 계획적이지 않은 코인 물타기를 하면 더 큰 손실을 가져오게 된다.<br/><br/>
                코인을 매수하기 위해서는 코인 거래 차트를 가장 먼저 알아야 한다. 차트에서 알 수 있는 가장 중요한 내용은 지지선 저항선을 알아야 한다. 그리고 지지선, 저항선을 기준으로 코인을 매수하기 위해 1차 매수, 2차 매수, 3차 매수의 계획을 가져야 하며 수익 또는 손절을 하기 위해서 1차 매도, 2차 매도, 3차 매도의 기준을 잡고 분할 매도가 이루어져야 한다.<br/><br/>
                그래서 물타기란 손실에 대한 주가 하락에 대한 지지선의 기준을 가지고 계획적으로 분할 매수 하여 코인 평균 매수 단가를 낮추고 코인 물량을 늘려서 수익에 대한 확률을 높이는 것이 가장 큰 목적이다. 코인 물타기 계산기는 코인 가격에 대한 지지선 저항선을 확인하여 계획적으로 주가에 대한 매수가, 매수 수량, 평균 단가를 예측하여 분할 매수, 분할 매도할 수 있도록 돕는 계산기이다.<br/>
              </p>
            </div>
            {/*  */}
            <h2 style={{marginTop: 30}}>코인 차트</h2>
            <div>
              <p>
                코인 차트는 기업이 가지고 있는 가치에 대한 움직임을 한눈에 볼 수 있도록 주가를 선으로 이어서 만든 주가의 동향이다. 주가는 매초, 매분, 매시간 움직임을 파악해야 한다. 그래서 차트를 보면 시간을 가지고 움직이는 그래프를 분, 시간, 일, 달, 년으로 나누어 볼 수 있다.<br/><br/>
                코인 차트에는 틱, 시간에 대한 주가 움직임을 볼 수 있도록 지원하고 있다. 시간은 일정 시간 동안 주가의 움직임을 선으로 이어서 보여주는 것이고 틱은 주가의 움직이는 수량을 기준으로 그래프로 이어서 차트에 보여주게 된다. 그래서 시간은 일정 시간 동안 거래된 주가의 움직임을 볼 수 있으며 틱은 주가의 움직임에 대한 기준만을 가지고 차트에 나타나게 된다.<br/><br/>
                시간과 틱을 기준으로 차트를 보는 것은 주가의 움직임을 파악하는데 분명 다른 차이를 가지고 있다. 시간을 기준으로 차트를 보면 특정 시간에 얼마나 많은 매수, 매도가 이루어지는지 파악이 가능하며 틱을 기준으로 차트를 보면 주가의 매수, 매도 수량에 대한 기준으로 차트의 그래프를 볼 수 있어 어떤 주가에 매수 또는 매도가 이루어지는지 파악하기가 용이하다.<br/><br/>
                하지만 코인을 처음 시작하는 초보자라면 코인 매매하기 위해서 주가에 대한 지지선과 저항선을 가장 먼저 알아야 한다. 지지선 또는 저항선은 코인 물타기 계산기를 이용하여 분할 매수, 분할 매도를 체계적으로 계획을 세워 매매해야 한다.
              </p>
            </div>
            {/*  */}
            <h2 style={{marginTop: 30}}>지지선</h2>
            <div>
              <p>
                코인 차트에서 지지선이란 주가가 하락할 때 일정 가격에서 매수가 많이 이루어져 주가 하락을 막아주는 코인의 가격이라 생각하면 된다. 만약 1000원인 코인이 하락하는데 800원이라는 금액에서 누군가 계속 매수하게 된다면 800원에서 쉽게 떨어지지 않는다. 바로 이 코인의 800원 가격에서 매수하는 수량이 더 많게 되면 하락에서 상승으로 변하는 변곡점이 된다.<br/><br/>
                주가가 하락하지 않도록 일정 코인 가격에서 매수가 많이 이루어지는 구역을 지지선이라 한다. 지지선에서는 매수를 고려해야 하는 가장 첫걸음이 되는 코인의 가격이 된다. 이런 지지선에서 매수하게 되면 하락의 가능성이 작어지고 상승의 가능성이 더 큰 구간이기 때문에 손실보다는 수익에 대한 확률이 높아진다.<br/><br/>
                코인 차트에서 기업의 구가에 대한 지지선을 3개 정도 찾아서 1차 매수, 2차 매수, 3차 매수를 하는 것이 바로 계획적인 분할 매수 또는 코인 물타기가 된다. 코인 물타기는 지지선에서 계획적으로 하게 된다면 평균 매수가를 낮추고 낮은 금액에서 코인 물량을 늘려가는 좋은 매수 투자 기법이 된다.
              </p>
            </div>
            {/*  */}
            <h2 style={{marginTop: 30}}>저항선</h2>
            <div>
              <p>
                저항선은 지지선의 반대되는 개념으로 이해하면 쉽다. 저항선이란? 주가가 상승할 때 일정 가격에서 매도가 많이 이루어져 주가 상승을 막고 코인 가격의 하락을 가져오는 변곡점이라 생각하면 된다.<br/><br/>
                일정 주가 가격에서 매도가 많이 이루어져 주가의 상승을 가로막는 가격대가 코인 가격이 상승하는 저항선이 된다. 그래서 저항선에서는 수익 실현을 위해 1차 매도하며 차트에서 3가지 저항선 가격 대역을 찾아 분할 매도에 활용하게 된다.<br/><br/>
                코인 차트에서 기업의 구가에 대한 지지선을 3개 정도 찾아서 1차 매수, 2차 매수, 3차 매수를 하는 것이 바로 계획적인 분할 매수 또는 코인 물타기가 된다. 코인 물타기는 지지선에서 계획적으로 하게 된다면 평균 매수가를 낮추고 낮은 금액에서 코인 물량을 늘려가는 좋은 매수 투자 기법이 된다.
              </p>
            </div>
            {/*  */}
            <h2 style={{marginTop: 30}}>작동법</h2>
            <div>
              <p>
                계산기는 투자 자산의 평균 가격을 구하기 위해 먼저 총 구매 가격을 계산한 뒤, 이를 총 구매 수량으로 나눕니다.
              </p>
              <ul>
                <li>1. <strong>총 구매 가격</strong> = Σ(코인 가격 * 코인 수)</li>
                <li>2. <strong>총 수량</strong> = Σ(코인 수)</li>
                <li>3. <strong>평균 가격</strong> = 총 구매 가격 / 총 수량</li>
              </ul>
              <p>
                예를 들어 현재 100의 가격에 100주의 코인을 보유하고 있고, 새로 200의 가격에 200주의 코인을 매수한다고 가정합시다. 이 경우 총 구매 가격은 100x100 + 200x200 = 50000입니다. 이를 총 구매 개수로 나누면 50000 / 300 ≈ 167입니다. 따라서 코인의 평균 가격은 약 167입니다.
              </p>
            </div>
          </UsageContainer>
        </Container>
      </Wrap>
    </>
    )
}

const Wrap = styled.div`
  width: 100%;
  padding: 70px 20px 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 500px) {
    padding: 70px 0px 0px;
  }

  h1 {
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 10px;
  }
`;

const Container = styled.main`
  width: 100%;
  max-width: 620px;
  padding: 24px;
  background-color: white;
  border-radius: 10px;
  border: 1px solid ${GREY_05};
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media screen and (max-width: 500px) {
    border-radius: unset;
    max-width: 100%;
    border-top: 1px solid ${GREY_05};
    padding: 24px;
  }
`;

const InputContainer = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  gap: 10px;  
`;

const HeaderContainer = styled.div`
  border-bottom: 1px solid ${GREY_05};
  margin-bottom: 8px;
  padding-bottom: 8px;
  width: 100%;
  align-items: center;
  display: flex;
  color: ${MAIN_COLOR};
  font-weight: bold;
  gap: 10px;  
`;

const ResultContainer = styled.div`
  border-top: 1px solid ${GREY_08};
  margin-top: 8px;
  padding-top: 8px;
  width: 100%;
  align-items: center;
  display: flex;
  color: ${MAIN_COLOR};
  font-weight: bold;
  gap: 10px;  
`;

const TotalParagraph = styled.p<{ minWidthProp?: boolean }>`
  display: flex;
  width: ${props => !props.minWidthProp ? '100%' : '50%'};
  overflow-x: scroll;
  padding: 6px 10px;
  font-size: 16px;

  span {
    display: block;
    width: 100%;
    text-align: right;
  }
`

const TotalResultParagraph = styled.p<{ minWidthProp?: boolean }>`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: ${props => !props.minWidthProp ? '100%' : '50%'};
  padding: 6px 10px;
  font-size: 16px;

  span {
    &:last-child {
      font-size: 24px;
    }
  }
`

const ContentDiv = styled.div`
  margin-top: 10px;
  width: 100%;
  margin-bottom: 20px;

  h2 {
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 20px;
    padding-top: 40px;
    border-top: 1px solid ${GREY_05};

    &::before {
      content: '';
      border-left: 6px solid ${MAIN_COLOR};
      margin-right: 10px;
    }
  }

  h3 {
    font-size: 26px;
    font-weight: bold;
    margin: 16px 0;
    border-top: 1px solid ${GREY_05};
    padding-top: 16px;
  }

  p {
    line-height: 26px;
  }
`;

const ContentDescriptionDiv = styled.ul`
  width: 100%;
  background-color: ${GREY_09};
  padding: 20px;
  border-radius: 10px;

  h2 {
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  h3 {
    font-size: 26px;
    font-weight: bold;
    margin: 16px 0;
    border-top: 1px solid ${GREY_05};
    padding-top: 16px;
  }

  li {
    line-height: 26px;
  }
`;

const UsageContainer = styled.div`
  border-top: 1px solid ${GREY_05};
  padding-top: 40px;
  margin-top: 40px;
  max-width: 570px;

  h2 {
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 20px;

    &::before {
      content: '';
      border-left: 6px solid ${MAIN_COLOR};
      margin-right: 10px;
    }

  }

  div {
    line-height: 24px;
    padding-bottom: 20px;
    border-bottom: 1px solid ${GREY_05};

    &:last-child {
      border: none;
      padding-bottom: 0px;
    }

    strong {
      font-weight: bold;
    }
  }

  ul {
    margin-top: 20px;
    margin-bottom: 20px;

    li {
      line-height: 26px;
    }
  }
`



export default UnitPrice

