export const MAIN_COLOR = "#122699";
export const SUB_COLOR = "#028BAB";
export const BLACK = "#000000";
export const GREY_02 = "#1E2025";
export const GREY_03 = "#444855";
export const GREY_04 = "#636874";
export const GREY_05 = "#858A93";
export const GREY_06 = "#A9ACB4";
export const GREY_07 = "#CBCDD3";
export const GREY_08 = "#DDDEE3";
export const GREY_09 = "#EAEBEF";
export const GREY_10 = "#F7F7F9";
export const WHITE = "#FFFFFF";
