import React, { useState, InputHTMLAttributes } from "react";
import styled from "styled-components";
import { GREY_02, GREY_08 } from "../../config/color";

/**
 * 신규주문에서 사용될 input 공용 컴포넌트 props
 * @property {string} id input id
 * @property {string} label input label
 * @property {string} unit input unit
 * @property {string} validation validation 메시지
 * @property {string} suffix input suffix
 * @property {boolean} required input 필수입력 여부
 * @property {boolean} disabled input disabled 여부
 * @property {string} boxClassName input 컴포넌트내에 실제 box에 부여될 className
 * @property {function} onReset input 입력값 초기화 함수
 * @property {React.ReactNode} labelChildren 타이틀영역에 들어갈 추가적인 요소들 (ex. 버튼)
 * @property {boolean} row 타이틀영역에 들어갈 추가적인 요소들 (ex. 버튼)
 */
interface IInputProps extends InputHTMLAttributes<HTMLInputElement> {
  id?: string;
  required?: boolean;
  disabled?: boolean;
  boxClassName?: string;
  suffix?: string;
  label?: string;
  unit?: string;
  validation?: string;
  onReset?: () => void;
  labelChildren?: React.ReactNode;
  searchChildren?: React.ReactNode;
  row?: boolean
}

/**
 * 신규주문에서 사용될 input 공용 컴포넌트
 * @returns {JSX.Element}
 */
const Input: React.FC<IInputProps> = ({
  id,
  label,
  unit,
  validation,
  required,
  disabled,
  labelChildren,
  searchChildren,
  boxClassName,
  suffix,
  row = true,
  onReset,
  ...rest
}): JSX.Element => {
  const [focused, setFocused] = useState<boolean>(false);

  return (
    <InputWrap focused={focused} row={row}>
      {label && <label>{label}</label>}
      <div>
        <input
          id={id}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          disabled={disabled}
          {...rest}
        />
        {unit && <span>{unit}</span>}
      </div>
    </InputWrap>
  );
};

const InputWrap = styled.div<{ focused: boolean, row: boolean }>`
  display: flex;
  flex-direction: ${(props) => props.row ? 'row' : 'column'};
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 16px;

  label {
    text-align: ${(props) => !props.row ? 'left' : 'right'};
    margin-bottom: ${(props) => !props.row && '10px'};
    width: 100%;
  }

  div {
    width: 100%;
    display: flex;
    align-items: flex-end;
    gap: 10px;

    input {
      width: 100%;
      border: 1px solid ${GREY_08};
      border: ${(props) => props.focused && `1px solid ${GREY_02}`};
      padding: 6px 10px;
      border-radius: 6px;
      text-align: right;
      font-size: 18px;
      height: 42px;
    }

    span {
      width: 50px;
    }
  }
`;

export default Input;
