import React from "react";
import { Header } from "./components";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Home, UnitPrice, StockUnitPrice } from "./pages";
import Compound from './pages/Compound';

const Router: React.FC = () => {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path={'/'} element={<Home />} />
        <Route path={'/compound'} element={<Compound />} />
        <Route path={'/unit-price'} element={<UnitPrice />} />
        <Route path={'/stock-unit-price'} element={<StockUnitPrice />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
