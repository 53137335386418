import React from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { useTranslation } from 'react-i18next';
import { slide as Menu } from 'react-burger-menu'
import { GREY_05 } from "../config/color";
import { GiHamburgerMenu } from 'react-icons/gi'
import { GREY_06 } from '../config/color/index';


const Header: React.FC = () => {
  const location = useLocation();
  const { t } = useTranslation()

  const [isOpen, setIsOpen] = React.useState<boolean>(false)

  const handleChangeRoute = () => {
    setIsOpen(false)
  }

  return (
    <HeaderWrap>
      <Container>
        <MenuContainer>
          <GiHamburgerMenu size={22} height={22} />
        </MenuContainer>
        <MenuContainer>
          <Link to="/">
          <img src="/apple-icon-120x120.png" alt="logo" />
          </Link>
        </MenuContainer>
        <MenuContainer />
      </Container>
      <Menu isOpen={isOpen}>
        <nav>
          <NavATag pathname={'/compound'} location={location.pathname} to={"/compound"} onClick={handleChangeRoute}>
            <span>{t('compoundTitle')}</span>
          </NavATag>
          <NavATag pathname={'/unit-price'} location={location.pathname} to={"/unit-price"} onClick={handleChangeRoute}>
            <span>{t('unitPriceTitle')}</span>
          </NavATag>
          <NavATag pathname={'/stock-unit-price'} location={location.pathname} to={"/stock-unit-price"} onClick={handleChangeRoute}>
            <span>{t('stockUnitPriceTitle')}</span>
          </NavATag>
        </nav>
      </Menu>
    </HeaderWrap>
  )
};

const HeaderWrap = styled.header`
  width: 100%;
  height: 40px;
  position: fixed;
  border-bottom: 1px solid ${GREY_05};
  background-color: white;

  nav {
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const MenuContainer = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;

  img {
    width: 24px;
    height: 24px;
  }
`

const NavATag = styled(Link)<{pathname: string, location: string}>`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  font-weight: ${props => props.pathname === props.location && 'bold'};
  color: ${props => props.pathname === props.location ? 'black' : `${GREY_06}`};
  padding: 12px;
  margin-bottom: 20px;
`


export default Header;
