import _ from "lodash";
import numeral from "numeral";
import React from "react";
import styled from "styled-components";
import Input from "../components/input";
import { CSVLink } from "react-csv";
import { GREY_05, GREY_08, MAIN_COLOR, WHITE } from "../config/color";
import ExcelIcon from "../assets/icon-excel.svg";
import { useTranslation } from "react-i18next";
import Meta from "../utils/meta";
import i18n from 'i18next';

interface InputStateProps {
  principal: number;
  period: number;
  rate: number;
}



const Compound: React.FC = () => {
  const { t } = useTranslation()
  const [inputState, setInputState] = React.useState<InputStateProps>({
    principal: 0,
    period: 0,
    rate: 0,
  });
  const [theArray, setTheArray] = React.useState<any>([]);
  const listRef = React.useRef<HTMLDivElement>(null);

  const { principal, period, rate } = inputState;

  const onContactClick = () => {
    listRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setTheArray([]);
    setTimeout(() => {
      getComputedInterest();
    }, 500);
    setTimeout(() => {
      onContactClick();
    }, 1000);
  };

  const getComputedInterest = () => {
    let result = 0;
    let numberPrincipal = principal.toString().replace(/,/g, "");
    for (let i = 1; i <= Number(period); i++) {
      result = Math.round(
        Number(numberPrincipal) * Math.pow(1 + Number(rate) / 100, i)
      );
      const profit =
        result -
        Math.round(
          Number(numberPrincipal) * Math.pow(1 + Number(rate) / 100, i - 1)
        );
      const profitRate = ((result / Number(numberPrincipal) - 1) * 100).toFixed(
        2
      );

      const newElement = {
        result: +result,
        profit: +profit,
        profitRate: +profitRate,
      };

      setTheArray((oldArray: any) => [...oldArray, newElement]);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (_.isNaN(+e.target.value.toString().replaceAll(",", ""))) return;

    setInputState({
      ...inputState,
      [e.target.name]: e.target.value,
    });
  };

  const getDecimalComma = (num: number) => {
    return numeral(num).format("0,0");
  };

  const getDecimalCommaFloat = (num: number) => {
    return numeral(num).format("0,0.00");
  };

  const listForExcel = theArray?.map(
    (
      item: { profit: number; result: number; profitRate: number },
      idx: any
    ) => {
      return [
        `${idx + 1}개월`,
        `${getDecimalComma(item.profit)}원`,
        `${getDecimalComma(item.result)}원`,
        `${getDecimalCommaFloat(item.profitRate)}%`,
      ];
    }
  );

  return (
    <>
      <Meta
        ogTitle={t('ogTitle')}
        title={t('compoundTitle')}
        siteUrl={'https://www.support-cal.com/'}
      />
      <Wrap>
        <h1>{t('compoundTitle')}</h1>
        <hr />
        <Container>
          <InputContainer>
            <form onSubmit={handleFormSubmit}>
              <Input
                required
                row={false}
                name="principal"
                value={principal ? getDecimalComma(principal) : ""}
                placeholder={"1,000,000"}
                label="투자 원금"
                onChange={handleChange}
              />
              <Input
                required
                row={false}
                name="period"
                value={period ? period : ""}
                placeholder={"24"}
                label="계산 기간"
                onChange={handleChange}
              />
              <Input
                required
                row={false}
                name="rate"
                value={rate ? rate : ""}
                placeholder={"1"}
                label="수익률"
                onChange={handleChange}
              />
              <ButtonContainer>
                <button type="submit">계산하기</button>
              </ButtonContainer>
            </form>
          </InputContainer>
          {!_.isEmpty(theArray) && (
            <ListContainer ref={listRef}>
              <CSVLink
                filename="be_rich.csv"
                headers={["기간", "수익", "총금액", "수익률"]}
                data={listForExcel}
              >
                <CSVButton>
                  <button type="button">
                    <img src={ExcelIcon} alt="excel icon" />
                    Excel Download
                  </button>
                </CSVButton>
              </CSVLink>
              <ListHeader>
                <p>기간</p>
                <p>수익</p>
                <p>총금액</p>
                <p>수익률</p>
              </ListHeader>
              <ul>
                {theArray.map((item: any, idx: number) => {
                  return (
                    <List key={idx}>
                      <p>
                        <span>{idx + 1}</span>
                        <span>개월</span>
                      </p>
                      <p>
                        <FontBold>{getDecimalComma(item.profit)}</FontBold>
                        <span>&nbsp;원</span>
                      </p>
                      <p>
                        <FontBold>{getDecimalComma(item.result)}</FontBold>
                        <span>&nbsp;원</span>
                      </p>
                      <p>
                        <FontBold>
                          {getDecimalCommaFloat(item.profitRate)}
                        </FontBold>
                        <span>%</span>
                      </p>
                    </List>
                  );
                })}
              </ul>
            </ListContainer>
          )}
          <ContentDiv>
            <div>
              <h2>단리와 복리의 차이</h2>
              <div>
                <p>
                  단리 방식은 원금에만 이자가 붙는 방식이고, 복리는 원금에 이자가
                  붙은 원리금에 이자가 붙는 방식입니다.
                </p>
                <br/><br/>
                <p>
                  단리 방식과 복리 방식의 차이 이해를 위해 예를 통해 좀 더
                  구체적으로 살펴 본 후 복리 계산법과 복리 계산기 활용하는 법에
                  대해 알아보겠습니다.
                </p>
                <h3>단리로 이자가 붙는 경우 공식</h3>
                <div>
                  <p>
                    예를 들어, 은행에 1,000 원을 연 이자율 10%로 3년간 은행에
                    예치할 경우에 단리로 이자가 붙는다면, 첫 번째 해 말에는 1,000
                    × 0.1 = 100의 이자가 붙어 원금과 이자의 합계는 1,000+(1,000
                    ×0.1) = 1,000×(1+0.1)이 되고, 
                    <span>{` 두 번째 해 말에도 1,000×0.1=100의 이자가 붙어 원금과 이자의 합계는 1,000+{(1,000×0.1)+(1,000×0.1)}=1,000×(1+2×0.1)이 되며,`}</span>
                    <span>{`세 번째 해 말에도 1,000 × 0.1 = 100의 이자가 붙어, 원금과 이자의 합계는 1,000+{(1,000×0.1)+(1,000×0.1)+(1,000×0.1)}=1,000×(1+3×0.1)이 됩니다.`}</span>
                  </p>
                  <br/><br/>
                  <p>
                    위 예에서 실제 수치를 원금과 이자율로 일반화 시켜 살펴보면, 첫
                    번째 해 말에는 원금×(1+1×이자율)이 되고, 두 번째 해 말에는
                    원금×(1+2×이자율), 세 번째 해 말에는 원금×(1+3×이자율)이 됨을
                    확인할 수 있습니다.
                  </p>
                  <br/><br/>
                  <p>
                    여기서 1, 2, 3은 예치 기간이므로 단리 적용시 원금과 이자의
                    합계 공식은 아래와 같이 표현할 수 있습니다.
                  </p>
                  <p>{`원금 × {1 + (예치기간×이자율)}`}</p>
                </div>
                <h3>복리로 이자가 붙는 경우</h3>
                <div>
                  <p>
                    이번에는 매년 말 복리로 이자가 붙는다고 가정하면 3년간 이자와
                    원금 합계는 다음과 같습니다.
                  </p>
                  <br/><br/>
                  <p>
                    첫 번째 해 말의 이자는 1,000 × 0.1 = 100으로 단리의 경우와
                    같습니다. 따라서 첫 번째 해 말의 원리합계 금액도 1,000 × (1 +
                    0.1)로 단리 경우와 같습니다.
                  </p>
                  <br/><br/>
                  <p>
                    단리와 복리가 달라지는 시점은 두 번째 이자가 붙는 시점부터
                    입니다.
                  </p>
                  <br/><br/>
                  <p>
                    {`두 번째 해에는 1,000×(1+0.1)로 불어난 원리금에 이자가 붙어 1,000×(1+0.1)×0.1의 생기므로 총 원리합계 금액은 {1,000×(1+0.1)} + {1,000×(1+0.1)×0.1} = 1,000×(1+0.1)×(1+0.1) = 1,000×(1+0.1)2입니다.`}<br/><br/>
                  </p>
                  <p>
                    {`세 번째 해에는 1,000×(1+0.1)2로 불어난 원리금에 다시 10%의 이자가 붙어 1,000×(1+0.1)2×0.1의 이자가 생기므로 원리합계 금액은 {1,000×(1+0.1)2} + {1,000×(1+0.1)2×0.1)} = 1,000×(1+0.1)2×(1+0.1) = 1,000×(1+0.1)3이 됩니다.`}
                  </p>
                </div>
              </div>
            </div>
          </ContentDiv>
        </Container>
      </Wrap>
    </>
  );
};

const Wrap = styled.div`
  width: 100%;
  padding: 70px 20px 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 500px) {
    padding: 70px 0px 0px;
  }

  h1 {
    font-weight: 700;
    font-size: 26px;
    margin-bottom: 10px;
  }
`;


const Container = styled.main`
  width: 100%;
  max-width: 620px;
  padding: 24px;
  background-color: white;
  border-radius: 10px;
  border: 1px solid ${GREY_05};
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media screen and (max-width: 500px) {
    border-radius: unset;
    max-width: 100%;
    border-top: 1px solid ${GREY_05};
    padding: 24px;
  }
`;

const InputContainer = styled.div`
  width: 100%;

  form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  margin-top: 20px;
  button {
    width: 100%;
    font-size: 16px;
    height: 40px;
    border-radius: 4px;
    background-color: ${MAIN_COLOR};
    color: ${WHITE};
    font-weight: bold;
    text-align: center;
  }
`;

const ListContainer = styled.div`
  width: 100%;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid ${GREY_08};
`;

const ListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: ${MAIN_COLOR};

  p {
    width: 100%;
    padding: 12px 10px 10px 10px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: ${WHITE};
  }
`;

const List = styled.li`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 16px;
  padding-bottom: 14px;
  border-bottom: 1px solid ${GREY_08};

  p {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
  }
`;

const FontBold = styled.span`
  font-weight: 700;
`;

const CSVButton = styled.button`
  width: 100%;
  margin-bottom: 10px;
  height: 30px;
  display: flex;
  justify-content: flex-end;

  button {
    display: flex;
    gap: 6px;
    border: 1px solid ${GREY_05};
    border-radius: 4px;
    padding: 8px;
    font-size: 14px;
    font-weight: bold;
  }
`;

const ContentDiv = styled.div`
  margin-top: 40px;

  h2 {
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 20px;
    padding-top: 40px;
    border-top: 1px solid ${GREY_05};

    &::before {
      content: '';
      border-left: 6px solid ${MAIN_COLOR};
      margin-right: 10px;
    }
  }

  h3 {
    font-size: 26px;
    font-weight: bold;
    margin: 40px 0 20px;
    border-top: 1px solid ${GREY_05};
    padding-top: 40px;

    &::before {
      content: '';
      border-left: 6px solid ${MAIN_COLOR};
      margin-right: 10px;
    }
  }

  p {
    line-height: 26px;
  }
`;

export default Compound;
